<template>
	<b-card title="Banner Content List">
		<b-row class="mb-3">
			
			<b-col cols="12" class="mt-2">
				<!-- hide because there is no filter in current feature -->
				<!-- <button
					class="btn btn-outline-secondary mr-2"
					@click="filter = {}" 
				>
					Reset
				</button> -->
				<router-link class="btn btn-primary mr-2" to="/create-banner-content">
					Tambah Data
				</router-link>
			</b-col>

		</b-row>
		
		<Table 
			:result="result"
			:banner="banner"
			:is-loading="isLoading"
			:get-data="getData"
			:delete-item="deleteItem"
			:current-page="currentPage"
			@filter="getFilter"
		/>
	</b-card>
</template>

<script>
import Export from '@/components/Export.vue'
import Table from '@/components/banner-content/Table.vue'
import { successNotification, errorNotification } from '@/auth/utils'
import { 
	BRow, 
	BCol, 
	BCard,
	BTable,
	BFormInput,  
	BButton, 
	BSpinner,
	VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
export default {
	components: {
		Export,
		Table,
		BCard,
		BTable,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormInput,
		vSelect,
		flatPickr,
	},
	directives: {
	'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			currentPage: 1,
			isLoading: false,
			result: [],
			banner: {},
			link:'',
			isLoadingExport: false,
			filter: {},
			resultExport: [],
			sort: '',
			category: [],
			filter: {
				title: '',
			},
		}
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},
	created() {
		this.getData()
	},
	mounted() {
	},
	methods: {
		getFilter(value) {
			this.filter.order_type = value.order_type
		},
		submitFilter() {
			this.getData(this.currentPage)
		},
		clearFilter() {
			this.filter = {
				title: '',
			}
		},
		getData(page) {
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
    		queryParams.page = page
			this.$http.get('/admin/banner-content', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.banner = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			}).catch(error => {
			})	
		},
		deleteItem(slug) {
			this.$swal({
				title: 'Are you sure?',
				text: 'Are you sure to delete this banner content?',
				icon: 'warning',
				showCancelButton: true,
				customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
				this.isLoading = true
				this.$http.delete(`/admin/banner-content/${slug}`)
					.then(response => {
					this.getData(this.currentPage)
					this.$swal({
						icon: 'success',
						title: 'Success!',
						text: 'Banner Content successfully deleted',
						customClass: {
						confirmButton: 'btn btn-success',
						},
					})
					this.isLoading = false
					})
					.catch(error => {
					if (error.response.data.meta.messages.length > 0) {
						errorNotification(this, 'Oops!', error.response.data.meta.messages)
					}
					})
				}
			})
		},
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>